<template>
  <div>
    <app-header></app-header>
    <div class="m-4">
      <div class="card">
        <div class="card-header d-flex justify-content-between align-items-center">
          <h1 class="mb-0">Notifications</h1>
          <button class="btn btn-primary" @click="refreshNotifications" :disabled="isLoading">
            <span v-if="isLoading" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
            <i v-else class="uil uil-sync me-1"></i>
            Refresh
          </button>
        </div>
        <div class="card-body">
          <div v-if="isLoading" class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div v-else-if="error" class="alert alert-danger">Error: {{ error }}</div>
          <div v-else>
            <table class="table table-hover table-fixed">
              <thead>
                <tr>
                  <th class="col-id">ID</th>
                  <th class="col-title">Title</th>
                  <th class="col-progress">Progress</th>
                  <th class="col-status">Status</th>
                  <th class="col-date">Created At</th>
                  <th class="col-timestamp">Timestamp</th>
                  <th class="col-date">Last Updated At</th>
                  <th class="col-actions">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="job in notifications?.results" :key="job.id">
                  <td>{{ job.id }}</td>
                  <td>{{ job.title }}</td>
                  <td>{{ job.percent_complete }} %</td>
                  <td>
                    <span :class="['badge', 'fs-6', 'p-2', getStatusBadgeClass(job)]">{{ getStatusText(job) }}</span>
                  </td>
                  <td>{{ $filters.datetimeformat(job.created) }}</td>
                  <td>{{ getTimestamp(job) }}</td>
                  <td>{{ $filters.datetimeformat(job.updated) }}</td>
                  <td>
                    <button type="button" class="btn btn-primary me-2" @click="openLogsModal(job)">See Logs</button>
                    <button 
                      v-if="job.is_active" 
                      type="button" 
                      class="btn btn-danger mt-2" 
                      @click="cancelJob(job.id)"
                      :disabled="isCancelling === job.id"
                    >
                      Cancel
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

            <pagination
              :items="notifications"
              :currentPage="page"
              :perPage="perPage"
              @pagechanged="onPageChanged"
            >
            </pagination>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
    <div class="modal fade" id="logsModal" tabindex="-1" aria-labelledby="logsModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-dark text-light">
            <h5 class="modal-title" id="logsModalLabel">
              <i class="bi bi-terminal"></i> Logs for Job {{ selectedJob?.id }}
            </h5>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body p-0">
            <div class="shell-output">
              <pre><code>{{ selectedJob?.execution_log || 'No logs available for this job.' }}</code></pre>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Modal } from 'bootstrap'

import Header from '@/components/Layout/Header.vue'
import Footer from '@/components/Layout/Footer.vue'

export default {
  name: 'Jobs',
  components: {
    'app-header': Header,
    'app-footer': Footer,
  },
  data() {
    return {
      page: 1,
      perPage: 15,
      isLoading: false,
      error: null,
      selectedJob: null,
      modal: null,
      isCancelling: null,
      pollInterval: null,
    }
  },
  mounted() {
    this.modal = new Modal(document.getElementById('logsModal'))
  },
  computed: {
    ...mapState({
      notifications: state => state.investors.notifications,
    }),
  },
  created() {
    this.loadNotifications()
    this.startPolling()
  },
  beforeUnmount() {
    this.stopPolling()
  },
  methods: {
    openLogsModal(job) {
      this.$store.dispatch('RETRIEVE_JOB', job.id)
          .then(retrievedJob => {
            this.selectedJob = retrievedJob.data;
        })
        .catch(error => {
          console.log(error);
          this.selectedJob = job;
        })
        .finally(() => {
          this.modal.show()
        })
    },
    getTimestamp(job) {
      if (job.is_completed && job.completed) return `Completed at ${this.$filters.datetimeformat(job.completed)}`
      if (job.is_cancelled && job.cancelled_at) return `Cancelled at ${this.$filters.datetimeformat(job.cancelled_at)}`
      if (job.is_failed && job.failed_at) return `Failed at ${this.$filters.datetimeformat(job.failed_at)}`
      return job.is_active ? 'In progress' : ''
    },
    refreshNotifications() {
      this.loadNotifications();
    },
    loadNotifications() {
      this.isLoading = true
      this.error = null
      this.$store.dispatch('LOAD_NOTIFICATIONS', { page: this.page, page_size: this.perPage })
        .then(() => {
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          this.error = error.message || 'An error occurred while loading notifications'
        })
    },
    onPageChanged(pageNumber) {
      this.page = pageNumber
      this.loadNotifications()
    },
    getStatusBadgeClass(job) {
      if (job.is_completed) return 'text-bg-success'
      if (job.is_failed) return 'text-bg-danger'
      if (job.is_cancelled) return 'text-bg-warning'
      if (job.is_active) return 'text-bg-primary'
      return 'text-bg-secondary'
    },
    getStatusClass(job) {
      if (job.is_completed) return 'status-completed'
      if (job.is_failed) return 'status-failed'
      if (job.is_cancelled) return 'status-cancelled'
      if (job.is_active) return 'status-active'
      return 'status-pending'
    },
    getStatusText(job) {
      if (job.is_completed) return 'Completed'
      if (job.is_failed) return 'Failed'
      if (job.is_cancelled) return 'Cancelled'
      if (job.is_active) return 'Active'
      return 'Pending'
    },
    formatDate(dateString) {
      return new Date(dateString).toLocaleString()
    },
    cancelJob(jobId) {
      this.isCancelling = jobId
      return this.$store.dispatch('CANCEL_BACKGROUND_JOB', jobId)
        .then(() => {
          this.loadNotifications()
        })
        .finally(() => {
          this.isCancelling = null
        })
    },
    startPolling() {
      this.stopPolling()
      console.log('starting polling for jobs')
      this.pollInterval = setInterval(() => {
        this.loadNotifications()
      }, 5000)
      console.log('started polling for jobs')
    },
    stopPolling() {
      console.log('stopping polling for jobs')
      if (this.pollInterval) {
        clearInterval(this.pollInterval)
        this.pollInterval = null
      }
      console.log('stopped polling for jobs')
    },
  },
  watch: {
    jobs() {
      if (this.selectedJob) {
        this.selectedJob = this.jobs.find(job => job.id === this.selectedJob.id)
      }
    }
  }
}
</script>

<style scoped>
.jobs-container {
  padding: 20px;
}
.table {
  width: 100%;
  border-collapse: collapse;
}
.table-fixed {
  table-layout: fixed;
  width: 100%;
}

.col-id { width: 5%; }
.col-title { width: 20%; }
.col-progress { width: 6%; }
.col-status { width: 10%; }
.col-date { width: 15%; }  /* Increased from 12% to 15% */
.col-timestamp { width: 15%; }  /* Increased from 15% to 19% */
.col-actions { width: 10%; }

.table th, .table td {
  word-wrap: break-word;
  white-space: normal;
}

.table th {
  vertical-align: top;
}

.table td {
  vertical-align: middle;
}

.table td.timestamp {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.status-completed { color: green; }
.status-failed { color: red; }
.status-cancelled { color: orange; }
.status-active { color: blue; }
.status-pending { color: gray; }
.modal-body {
  padding: 0 !important;
}

.shell-output {
  background-color: #1e1e1e;
  color: #f0f0f0;
  font-family: 'Courier New', Courier, monospace;
  padding: 1rem;
  max-height: 400px;
  overflow-y: auto;
}

.shell-output pre {
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.shell-output code {
  display: block;
  line-height: 1.5;
}
</style>