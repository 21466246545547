<template>
  <div>
    <div class="card">
      <div class="card-body">
        <!-- Tabs -->
        <ul class="nav nav-tabs justify-content-center">
          <li class="nav-item">
            <router-link 
              class="nav-link" 
              :class="{ active: $route.name === 'pitch_deck_upload' }"
              to="/pitch-deck">
              Upload Pitch Deck
            </router-link>
          </li>
          <li class="nav-item">
            <router-link 
              class="nav-link" 
              :class="{ active: $route.name.startsWith('pitch_deck_report') }"
              to="/pitch-deck/reports">
              Analysis Reports
            </router-link>
          </li>
        </ul>

        <!-- Content -->
        <div class="tab-content mt-3">
          <router-view v-slot="{ Component }">
            <component :is="Component" />
          </router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Header from '@/components/Layout/Header.vue'
import Footer from '@/components/Layout/Footer.vue'

export default {
  name: 'PitchDeckLayout',

  components: {
    AppHeader: Header,
    AppFooter: Footer
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      siteCompany: state => state.auth.siteCompany
    })
  }
}
</script>
  