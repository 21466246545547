<template>
    <div>
        <div class="card">
            <div class="card-header">
                <h5>Keys</h5>
            </div>
            <div class="card-body">
                <router-view v-slot="{ Component }">
                    <component :is="Component" />
                </router-view>
            </div>
        </div>
    </div>
</template>

<script>
import Header from '@/components/Layout/Header.vue'
import Footer from '@/components/Layout/Footer.vue'

export default {
    name: 'KeyLayout',

    components: {
        Header,
        Footer
    }
}
</script>