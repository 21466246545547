<template>
  <div>
    <app-header></app-header>
    <div class="container-fluid">

      <div v-if="user" class="row mt-3">

        <div class="col pe-0">
          <div class="card">
            <div class="card-header d-flex justify-content-between align-items-center">
              <h3>Generate Startup Invitation for your company to create profile</h3>
              <div>
                <button class="btn btn-primary me-2" @click="createInvitation" :disabled="isLoading">
                  <span v-if="isLoading" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                  <i v-else class="uil uil-plus me-1"></i>
                  Create new invitation
                </button>
                <button class="btn btn-primary" @click="loadInvitations" :disabled="isLoading">
                  <span v-if="isLoading" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                  <i v-else class="uil uil-sync me-1"></i>
                  Refresh
                </button>
              </div>
            </div>
            <div class="card-body">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Created by</th>
                    <th scope="col">Date</th>
                    <th scope="col">Link</th>
                    <th scope="col">Status</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="invitation in invitations?.results" :key="invitation.id">
                    <td>
                      {{ invitation.created_by?.email }}
                      <template v-if="invitation.created_by?.first_name || invitation.created_by?.last_name">
                        ({{ invitation.created_by?.first_name }} {{ invitation.created_by?.last_name }})
                      </template>
                    </td>
                    <td>
                      {{ $filters.datetimeformat(invitation.created_at) }}
                    </td>
                    <td>
                      <div class="d-flex align-items-center">
                        <a :href="invitation.link" target="_blank" rel="noopener noreferrer" class="me-2">
                          {{ invitation.link }}
                        </a>
                        <button 
                          class="btn btn-sm btn-outline-secondary"
                          :disabled="!invitation.is_active"
                          :class="{ 'btn-success': invitation.justCopied }"
                          @click="copyToClipboard(invitation)"
                          title="Copy link"
                        >
                          <i class="uil" :class="invitation.justCopied ? 'uil-check' : 'uil-copy'"></i>
                        </button>
                      </div>
                    </td>
                    <td>
                      {{ invitation.is_active ? 'This link is active' : 'This link has been used already' }}
                    </td>
                    <td>
                      <button class="btn btn-danger" @click="deleteInvitation(invitation.id)">
                        <i class="uil uil-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>

              <pagination
                :items="invitations"
                :currentPage="page"
                :perPage="perPage"
                @pagechanged="onPageChanged"
              >
              </pagination>

            </div>
          </div>
        </div>

      </div>

      <div v-else class="text-center">
        <loader size="32px"></loader>
      </div>

      <hr class="space space-md" />
      <app-footer></app-footer>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Header from '@/components/Layout/Header.vue'
import Footer from '@/components/Layout/Footer.vue'

export default {
  name: 'account',

  components: {
    'app-header': Header,
    'app-footer': Footer
  },

  data() {
    return {
      refreshInterval: null,
      user: null,
      errors: {},
      isSubmitting: false,
      page: 1,
      perPage: 10,
      isLoading: false,
      error: null,
    }
  },

  computed: {
    ...mapState({
      userState: state => state.auth.user,
      invitations: state => state.auth.invitations
    }),
    hasError() {
      return Object.keys(this.errors).length > 0
    }
  },
  
  mounted() {
    window.scrollTo(0, 0)

    this.user = this.userState

    this.startRefreshInterval()
  },

  beforeUnmount() {
    this.clearRefreshInterval()
  },

  created() {
    this.loadInvitations()
  },

  methods: {
    submit () {
      this.isSubmitting = true
      this.errors = {}

      this.$store.dispatch('UPDATE_USER', this.user)
        .then(() => {
          this.isSubmitting = false
        })
        .catch((error) => {
          this.isSubmitting = false
          this.errors = error.response.data
        })
    },
    goReplyIoKey() {
      this.$router.push({ name: 'reply-io-key' })
    },
    createInvitation() {
      this.isSubmitting = true
      this.$store.dispatch('CREATE_INVITATION')
        .finally(() => {
          this.loadInvitations()
        })
    },
    loadInvitations() {
      this.isLoading = true
      this.error = null
      this.$store.dispatch('LOAD_INVITATIONS', { page: this.page, page_size: this.perPage })
        .then(() => {
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          this.error = error.message || 'An error occurred while loading invitations'
        })
    },
    onPageChanged(pageNumber) {
      this.page = pageNumber
      this.loadInvitations()
      this.clearRefreshInterval()
      this.startRefreshInterval()
    },
    copyToClipboard(invitation) {
      navigator.clipboard.writeText(invitation.link)
        .then(() => {
          invitation.justCopied = true;
          setTimeout(() => {
            invitation.justCopied = false;
          }, 1000); // Reset after 1 second
        })
        .catch(err => {
          console.error('Failed to copy text: ', err)
        })
    },
    startRefreshInterval() {
      this.clearRefreshInterval()
      this.refreshInterval = setInterval(() => {
        this.loadInvitations();
      }, 5000);
    },
    clearRefreshInterval() {
      if (this.refreshInterval) {
        clearInterval(this.refreshInterval)
        this.refreshInterval = null
      }
    }
  }
}
</script>
