<template>
    <div class="container">
        <h1 class="mb-4">Configure Keys</h1>
        
        <div class="alert alert-info mb-4">
            <h4 class="alert-heading">Why do I need an API key?</h4>
            <p class="mb-0">
                Our platform leverages AI models from OpenAI (GPT) or Anthropic (Claude) to power various features 
                including pitch deck analysis, content generation, data insights, and other AI-assisted functionalities. 
                To use these services, you'll need to provide your own API key:
            </p>
        </div>

        <div class="alert alert-warning mb-4">
            <i class="bi bi-shield-lock"></i>
            Your API key is stored securely and is only used to communicate with the AI services for various functionalities that uses AI.
        </div>
        
        <div class="mb-3">
            <label for="modelSelect" class="form-label">Select AI Model</label>
            <select id="modelSelect" v-model="selectedModel" class="form-select">
                <option value="OPENAI">GPT (OpenAI)</option>
                <option value="CLAUDE">Claude (Anthropic)</option>
            </select>
        </div>

        <div class="mb-3">
            <label for="apiKey" class="form-label">API Key</label>
            <div class="input-group">
                <input 
                    :type="showKey ? 'text' : 'password'"
                    v-model="apiKey"
                    id="apiKey"
                    class="form-control"
                    :placeholder="apiKey ? '************' : 'Enter your API key'"
                />
                <button 
                    @click="toggleKeyVisibility" 
                    class="btn btn-outline-secondary"
                    type="button"
                >
                    {{ showKey ? 'Hide' : 'Show' }}
                </button>
            </div>
            <div class="form-text mt-2" v-if="selectedModel">
                <template v-if="selectedModel === 'OPENAI'">
                    Get your key from <a href="https://platform.openai.com/api-keys" target="_blank" rel="noopener">OpenAI's platform</a>
                </template>
                <template v-else-if="selectedModel === 'CLAUDE'">
                    Get your key from <a href="https://console.anthropic.com/account/keys" target="_blank" rel="noopener">Anthropic's console</a>
                </template>
                <p class="mt-2">
                    Note: Once saved, your API key is stored securely and will not be displayed again. Rest assured if you had 
                    any keys saved previously, they will be retained as it is irrespstive of how they appear in the UI.
                </p>
            </div>
        </div>

        <button 
            @click="saveKey" 
            class="btn btn-primary"
            :disabled="!apiKey || !selectedModel || !hasChanges"
        >
            Save Configuration
        </button>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'keys-page',

    data() {
        return {
            selectedModel: 'OPENAI',
            apiKey: '',
            showKey: false,
            originalModel: '',
            originalKey: ''
        }
    },
    computed: {
        ...mapState({
            keys: state => state.auth.keys,
        }),
        hasChanges() {
            return !this.keys?.llm_vendor || 
                   this.selectedModel !== this.originalModel || 
                   this.apiKey !== this.originalKey;
        }
    },
    methods: {
        ...mapActions('auth', ['LOAD_KEYS']),
        toggleKeyVisibility() {
            this.showKey = !this.showKey;
        },
        saveKey() {
            const payload = {
                llm_vendor: this.selectedModel
            };
            
            if (this.apiKey !== this.keys?.keys[this.selectedModel]) {
                payload.keys = {
                    [this.selectedModel]: this.apiKey
                };
            }

            this.$store.dispatch('UPDATE_KEYS', payload).then(() => {
                this.loadKeys()
            });
        },
        loadKeys() {
            this.$store.dispatch('LOAD_KEYS').then(() => {
                this.selectedModel = this.keys?.llm_vendor || 'OPENAI';
                this.apiKey = this.keys?.keys[this.selectedModel] || '';
                this.originalModel = this.selectedModel;
                this.originalKey = this.apiKey;
            })
        }
    },
    created() {
        this.loadKeys()
    },
    mounted() {
        this.loadKeys()
    },
    watch: {
        selectedModel: function() {
            const key = this.keys?.keys[this.selectedModel];
            this.apiKey = key ? key : '';
        }
    }
}
</script>