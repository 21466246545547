import axios from 'axios'

const state = {
  analysisHistory: [],
  pitchDeck: null
}

const actions = {
  LOAD_ANALYSIS_HISTORY({ commit, dispatch }) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}pitch_decks/`)
        .then((response) => {
          commit('SET_ANALYSIS_HISTORY', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },

  FETCH_PITCH_DECK({ commit, dispatch }, id) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}pitch_decks/${id}/`)
        .then((response) => {
          commit('SET_PITCH_DECK', response.data);
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },

  REANALYSE_PITCH_DECK({ commit, dispatch }, id) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.post(`${import.meta.env.VITE_API_URL}pitch_decks/${id}/reanalyse/`)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },

  ADD_PITCH_DECK({ commit, dispatch }, payload) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      const fileData = new FormData()
      fileData.append('name', payload.name)
      fileData.append('file', payload.file)
      axios.post(`${import.meta.env.VITE_API_URL}pitch_decks/`, fileData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  }
}

const getters = {}

const mutations = {
  SET_ANALYSIS_HISTORY(state, payload) {
    state.analysisHistory = payload
  },
  SET_PITCH_DECK(state, payload) {
    const report = payload.report;
    state.pitchDeck = payload
    state.pitchDeck.report = report.replace(/```markdown/g, '').replace(/```/g, '')
  }
}

export default {
  state,
  actions,
  mutations,
}
