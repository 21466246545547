<template>
  <div class="container">
    <div class="card-body pt-0">
      <p>Share your company's story and vision through your pitch deck. Please ensure your pitch deck is up-to-date and includes key metrics along with the LinkedIn profiles of the team members.</p>
      
      <div class="alert alert-info">
        <i class="fas fa-robot me-2"></i>
        Our AI-powered platform will analyze your pitch deck and provide:
        <ul class="mb-0 mt-2">
          <li>Comprehensive Investment Recommendation</li>
          <li>Detailed Risk and Opportunity Analysis</li>
          <li>Market Need and Size Assessment</li>
          <li>Innovation Analysis using Clayton Christensen's Framework</li>
          <li>Team Profile and Background Verification</li>
          <li>Defensibility and Competitive Advantage Analysis</li>
          <li>Scalability and Growth Potential Evaluation</li>
          <li>Traction and Revenue Analysis</li>
          <li>Overall Investment Confidence Score</li>
        </ul>
      </div>

      <div class="alert alert-success">
        <i class="fas fa-chart-line me-2"></i>
        You'll receive a detailed 10-section report with:
        <ul class="mb-0 mt-2">
          <li>Granular scoring across multiple metrics</li>
          <li>Evidence-based evaluation of $100M revenue potential</li>
          <li>Specific insights on scalability challenges</li>
          <li>Actionable recommendations for improvement</li>
        </ul>
      </div>

      <div>
        <div class="row mb-3">
          <div class="col-md-4">
            <label for="pitchDeckName" class="form-label">Pitch Deck Name</label>
            <input
              type="text"
              class="form-control"
              id="pitchDeckName"
              v-model="name"
              placeholder="Enter a name"
              required
            />
            <small class="text-muted">Enter a name for your pitch deck</small>
          </div>
          <div class="col-md-8">
            <label for="pitchDeck" class="form-label">Pitch Deck File</label>
            <input
              type="file"
              class="form-control"
              id="pitchDeck"
              @change="fileChange"
              accept=".pdf"
              required
            />
            <small class="text-muted">Upload your pitch deck (PDF)</small>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <button 
            class="btn btn-primary mb-2" 
            @click.prevent="submitPitchDeck"
            :disabled="!isFormValid"
          >
            <i class="fas fa-upload"></i>
            Upload Your Pitch Deck
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Swal from 'sweetalert2'
import CheckKeysMixin from '@/mixins/checkKeys'

export default {

  name: 'PitchDeckUpload',

  mixins: [CheckKeysMixin],

  data() {
    return {
      name: '',
      file: null
    }
  },

  computed: {
    ...mapState({
      keys: state => state.auth.keys,
    }),
    isFormValid() {
      return this.name.trim() !== '' && this.file !== null
    }
  },

  methods: {
    fileChange(event) {
      const files = event.target.files || event.dataTransfer.files
      if (!files.length) {
        return
      }
      this.file = files[0]
    },

    triggerPitchDeck(){
      this.$store.dispatch(
        'ADD_PITCH_DECK', 
        { name: this.name, file: this.file }
      )  
      this.$router.push({ name: 'pitch_deck_reports' })
    },

    async submitPitchDeck() {
      try {
        if(this.siteCompany.is_whitelabel){
          let task = 'pitch_deck_reports'
          let freeLimit = await this.checkFreeLimits(task)
          if(freeLimit && freeLimit !== undefined && freeLimit !== null){
            Swal.fire({
              title: "Generating Pitch Deck Analysis?",
              html: `This feature requires AI usage. You have ${freeLimit} free credit/s remaining.<br><br>
                    Using this feature will consume one credit.`,
              icon: "info",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, Do the Analysis",
              cancelButtonText: "Cancel"
            }).then((result) => {
              if (result.isConfirmed){
                this.triggerPitchDeck()
                let limits = this.siteCompany.free_limits;
                if (task in limits && limits[task] > 0){
                  limits[task] -= 1
                }
                this.updateFreeLimit(limits)
              }
            });
          } else {
            let shouldConclude = await this.checkKeys()
            if (shouldConclude) {
              return
            }
            this.triggerPitchDeck()
          }
        } else {
          this.triggerPitchDeck()
        }        
      } catch (error) {
        console.error('Failed to upload pitch deck:', error)
      }
    },
  }
}
</script>